<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-button
            :to="{path:'/product-inventory-manager/add-receiving'}"
            variant="primary"
            style="height: 60px"
            block
            class="d-flex align-items-center justify-content-center"
          >Thêm phiếu nhập</b-button>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-button
            :to="{path:'/product-inventory-manager/add-saling'}"
            variant="primary"
            style="height: 60px"
            block
            class="d-flex align-items-center justify-content-center"
          >Thêm phiếu xuất</b-button>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-button
            v-b-modal.ModalImportExcel
            variant="primary"
            style="height: 60px"
            block
            class="d-flex align-items-center justify-content-center"
          >Nhập Tồn đầu kỳ</b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <ModalImportBeginPeriod
      :indexStartTable="2"
      ref="ImportBeginPeriod"
      modalTitle="Nhập Tồn đầu kỳ"
      @onUpload="handleUploadFile"
    >
      <template #link-example>
        <a
          href="https://api-dev.hodo.app/api/go/static/uploads//1/nhap_thuoc_dau_ky_import.xlsx"
          target="_blank"
          class="link text-primary"
        >Mẫu tài liệu nhập Tồn đầu kỳ</a>
      </template>
    </ModalImportBeginPeriod>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";
import ModalImportBeginPeriod from "@/components/ImportExcel/index.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    ModalImportBeginPeriod
  },
  props: {
    statusFilter: {
      type: [Number, null],
      default: null
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleUploadFile(data) {
      const mapData = this.handleMapData(data);

      this.$store.commit("receiving/setDataImport", mapData);
      this.$refs.ImportBeginPeriod.onClose();
      this.$router.push({ name: "AddBeginPeriod" });
    },
    handleMapData(data) {
      return data?.map(item => ({
        stt: item.stt,
        id: item.id_thuoc,
        product_code: item.ma_thuoc,
        name: item.ten_thuoc,
        active_ingredient: item.hoat_chat,
        unit_id: item.don_vi,
        amount: item.so_luong,
        price_in: Number(item?.gia_mua?.toString()?.replaceAll(",", "")) || 0,
        vat_in: item.vat_mua,
        price_out: Number(item?.gia_ban?.toString()?.replaceAll(",", "")) || 0,
        vat_out: item.vat_ban,
        registration_number: item.so_dang_ky,
        lot_number: item.so_lo,
        expired_date: item.han_dung,
        supplier: item.nha_cung_cap
      }));
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
